<template>
    <fragment>
        <div class="d-md-flex justify-content-end py-3">
            <b-button variant="primary btn-mw" size="lg" @click="addBusinessCatgeory()">Add Business Category  <i class="fa fa-plus-square ml-1"></i></b-button>
            <b-modal id="business_category" :title="modalTitle" hide-footer @shown="resetForm">
                <div class="modal-area">
                    <ValidationObserver v-slot="{ passes }">
                        <ul class="list-unstyled mb-0 payment-single-transfer">
                            <form id="business_category" @submit.prevent="passes(addBusinessCategory)">
                                <li>  
                                    <ValidationProvider name="category_name" rules="required" v-slot="{ errors}">   
                                        <div class="form-group" :class="{'errors': errors.length && !businessCategory.category_name || errors[0],'success':businessCategory.category_name && !errors[0],'mb-0':apiErrorCode == 'E0065-003'} ">
                                            <span class="float-label">
                                                <input type="text" v-model="businessCategory.category_name" class="form-control" name="category_name " id="category_name " placeholder="Category Name*" @input="checkNameFormat($event, 'category_name')">
                                                <label for="category_name " class="control-label">Category Name*</label>
                                                <div class="line"></div>
                                                <i class="fa fa-times" v-if="errors.length && !businessCategory.category_name || errors[0] "></i>
                                                <i class="fa fa-check" v-if="businessCategory.category_name && !errors[0]"></i>
                                            </span>
                                            <span class="text-danger error-msg" v-if="fieldName == 'category_name'">{{fieldErrorMsg}}</span>
                                        </div>
                                    </ValidationProvider>
                                    <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0065-003'">{{apiErrorMsg}}</span> 

                                </li>
                                <li>
                                    <ValidationProvider name="business_type" rules="required" v-slot="{ errors}">
                                        <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !businessCategory.type,'success':businessCategory.type}">
                                            <span class="float-label">
                                                <label for="functions">Business Group*</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="businessCategory.type"  
                                                    :show-labels="false"
                                                    label="name"
                                                    :options="typeOptions" 
                                                    :searchable="false">
                                                </multiselect> 
                                                <i class="fa fa-times" v-if="errors.length && !businessCategory.type"></i>
                                                <i class="fa fa-check" v-if="businessCategory.type"></i>
                                            </span>
                                        </div>
                                    </ValidationProvider>
                                </li>
                                <li>
                                    <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                        <b-button v-if="businessCategoryId==''" type="submit" variant="primary btn-mw" size="lg">Add Business Category </b-button>
                                        <b-button v-if="businessCategoryId!=''" type="submit" variant="primary btn-mw" size="lg">Update Business Category </b-button>
                                        <b-button @click="$bvModal.hide('business_category')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                    </div>
                                </li>
                               
                            </form>
                        </ul>
                    </ValidationObserver>
                </div>
            </b-modal>
        </div>
        <b-table 
            ref="complianceTable"
            responsive
            outlined
            table-class="mb-0 table-custom"
            head-variant="light"
            :items="getComplianceListData" 
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection">
            <template #head()="{label,  field: { key }}">
                {{ label }}
                <!-- Custom icons -->
                <template>
                    <i v-if="sortBy !== key" class=""></i>
                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                </template>  
            </template>
            <template #cell(types)="row">
                <b-dropdown id="type" :text="row.item.types.selectedTypeName" right variant="outline-primary">
                    <b-dropdown-item v-for="(type,index) in row.item.types.typesOption" :key="index" :active="row.item.types.selectedTypeId == type.id" @click="updateType((currentPage-1) * perPage + row.index,type, row.item.types.selectedCategoryId,row.item.types.selectedCategoryName)">{{ type.name }}</b-dropdown-item>
                </b-dropdown>
            </template>
            <template #cell(action)="row">
                <b-dropdown id="action" text="Actions" right variant="outline-primary">
                    <b-dropdown-item @click="showEdit(row)">Edit </b-dropdown-item>
                    <b-dropdown-item @click="deleteCategory(row.item.categoryId)">Delete</b-dropdown-item>
                </b-dropdown>
            </template>
        </b-table>
        
        <!-- Table Pagination -->
        <div class="py-3 align-items-center justify-content-between table-pagination" :class="items.length > 0?' d-flex':'d-none'">
            <!-- v-if="totalRows > 10 -->
            <div class="per-page">
                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
            </div>
            <div class="number-page">
                <b-pagination 
                    v-model="currentPage" 
                    :total-rows="totalRows" 
                    :per-page="perPage"
                    hide-goto-end-buttons 
                    hide-ellipsis 
                    prev-text="Previous page" 
                    next-text="Next page" 
                    align="right">
                </b-pagination>
            </div>
            <div class="go-page">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Go To Page  </span>
                    </div>
                    <input type="text" v-model="currentPageNo" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                </div>
            </div>
            <main-loader ref="loader"></main-loader>
        </div>
         
    </fragment>
   
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
//import '@/plugins/sweetalert2'
import { Fragment } from 'vue-fragment'
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
import shared from "@/shared.js";
export default {
    name:'ComplianceListComponent',
    components: {
        Multiselect,
        ValidationObserver,
        ValidationProvider,
        Fragment,
        'main-loader':LoaderComponent
    },methods: {
        addBusinessCatgeory(){
            this.businessCategoryId = '';
            this.$bvModal.show('business_category');
        },
        resetForm(){
            if(this.businessCategoryId== '') {
                this.businessCategory.category_name = '';
                this.businessCategory.type = '';
                this.fieldErrorMsg = '';
                this.apiErrorMsg = '';
                this.apiErrorCode = '';
                this.fieldName = '';
                this.modalTitle = 'Add Business Category';
                
            } else {
                this.fieldErrorMsg = '';
                this.apiErrorMsg = '';
                this.apiErrorCode = '';
                this.fieldName = '';
                this.modalTitle = 'Edit Business Category';

            }
        },
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = this.currentPageNo = e.target.value
                } else {
                   this.currentPage = this.currentPageNo = paginationMenu;
                }
            } else {
                this.currentPage = this.currentPageNo = 1;
            }
        },
       
        checkNameFormat(e, fieldName) {
            let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
            let charLength = e.target.value.length;
            var splitStr = e.target.value.split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                if(splitStr[0]!='')
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
            }
            if(splitStr[0]=='') {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
            }

            this.businessCategory[fieldName] = splitStr.join(' ');
            if(/^[A-Za-z'-\s]+$/.test(char)) {
                if(charLength >= 2 && charLength <= 35 && splitStr[0]!='') {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
                    return true; // Match with regex 
                } else {
                    this.fieldName = fieldName;
                    this.fieldErrorMsg = 'Please provide minimum two characters without spaces at start.';
                }
            } else {
                this.fieldName = fieldName;
                this.fieldErrorMsg = 'Please provide valid value for category name';
                e.preventDefault(); // If not match, don't add to input text
            }

        },
        showEdit(element) {
            this.businessCategory.category_name = element.item.categoryName;
            this.businessCategory.type = {id: element.item.typeId, name:  element.item.typeName};
            this.businessCategoryId = element.item.categoryId;
            this.$bvModal.show('business_category');

        },
        deleteCategory(categoryId) {
            shared.deleteConfirm().then((result) => {
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    this.$http.delete(this.$baseurl+"business/category/"+categoryId,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    }).then((res) => {
                        if(res.data.code == '0065-000') {
                            shared.toastrSuccess(res.data.message);
                            this.getComplianceListData();
                            this.$refs.loader.hide();
                        } else {
                            this.$refs.loader.hide();
                            shared.toastrError(res.data.message);
                        }
                    }).catch((error) => {
                        this.apiErrorCode = error.response.data.code;
                        if(this.apiErrorCode == "E0065-005") {
                            this.$refs.loader.hide();
                            shared.toastrError(error.response.data.message);
                            
                        }/* else if(this.apiErrorCode == "E0004-003") {
                            this.$store.dispatch('contactDetail/addContact', loginData);
                            this.$router.push({ path: '/standapp-otp' })
                        }*/ else {
                            this.apiErrorMessage = error.response.data.message;
                        }
                    });



                }
            })
        },
        async getComplianceListData(ctx) {
            try {
                let page  = '';
                let size = '';
                let sortby = '';
                let sort = false;
                if(ctx.currentPage != undefined) {
                    page =  ctx.currentPage;
                } else {
                    page = 1;
                }
                if(ctx.perPage != undefined) {
                    size = ctx.perPage;
                } else {
                    size = 10;
                }
                if(ctx.sortBy == '') {
                    sortby = 'createdat'
                } else {
                    sortby = ctx.sortBy;
                }
                if(ctx.sortDesc == false) {
                    sort = 'desc'
                } else {
                    sort = 'asc';
                }

                
                this.currentPageNo = page;
                this.items = [];
                let paramData = {
                    sorting : sort,
                    sortby : sortby,
                    pagesize : size,
                    pagenumber : page
                }

               // if(ctx!=undefined) {
                    this.items = [];
                    const response = await this.$http.get(this.$baseurl+"business/category",{
                        params: paramData,
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") 
                        },
                    });
                    response.data.details.forEach(element => {
                        let complianceItem = {
                            categoryName:element.name,
                            categoryId:element.categoryid,
                            typeId: element.typeid,
                            typeName: element.typename,
                            types:{selectedCategoryName: element.name,selectedCategoryId: element.categoryid,selectedTypeId: element.typeid,selectedTypeName:element.typename,typesOption:this.typeOptions},
                            action: ''
                        };
                        this.items.push(complianceItem);
                    })
                    this.totalRows = response.data.query.total;
                    // this.currentPage = response.data.query.pagenumber;
                    return this.items;
                    
               // }
            }catch(error) {
                console.log(error);
            }
        },
        async getBusinessCategoryTypes() {
            this.types = [];
            this.categoryTypes = [];
            const response = await this.$http.get(this.$baseurl+"business/category/types",{
                 headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") 
                },
            });
            // console.log(response);
            response.data.details.forEach(element => {
                let catgeoryType = {
                    id:element.typeid,
                    name:element.typename,
                };
                this.categoryTypes.push(element.typename);
                this.typeOptions.push(catgeoryType);
            })
        },
        updateType(index,type,selectedCategoryId,selectedCategoryName){
            shared.updateConfirm().then((result) => {
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    let categoryData = {
                        name: selectedCategoryName,//this.items[index].types.selectedCategoryName,
                        typeid: type.id//this.items[index].types.selectedTypeId
                    };
                    // console.log(categoryData);
                    this.$http.put(this.$baseurl+"business/category/"+selectedCategoryId, categoryData, {
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    }).then((res) => {
                        if(res.data.code == '0065-000') {
                        // this.$swal('Success!', res.data.message, 'success', 'Close');
                            this.$refs.loader.hide();    
                            shared.toastrSuccess(res.data.message);
                            this.getComplianceListData();
                            
                        }
                    }).catch((error) => {
                        //this.$swal('Error!', error.response.data.message, 'error', 'Close');
                        this.$refs.loader.hide();
                        shared.toastrError(error.response.data.message);
                    });   
                }
                 
            })
        },
        addBusinessCategory(){
            if(this.businessCategoryId!='') {
                this.updateBusinessCategory();   
            } else {
                let categoryData = {
                    name: this.businessCategory.category_name,
                    typeid: this.businessCategory.type.id
                };
                
                this.$http.post(this.$baseurl+"business/category", categoryData,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                    },
                }).then((res) => {
                    if(res.data.code == '0065-000') {
                        shared.toastrSuccess(res.data.message);
                        this.getComplianceListData();
                        this.$bvModal.hide('business_category');
                        this.apiErrorMsg = '';
                        this.apiErrorCode = '';
                    }
                }).catch((error) => {
                    this.apiErrorCode = error.response.data.code;
                    this.apiErrorMsg = error.response.data.message;
                });
            }
           

            
        },
        updateBusinessCategory() {
            let categoryData = {
                name: this.businessCategory.category_name,
                typeid: this.businessCategory.type.id
            };
            this.$http.put(this.$baseurl+"business/category/"+this.businessCategoryId, categoryData,{
                 headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                },
            }).then((res) => {
                if(res.data.code == '0065-000') {
                    //this.$swal('Success!', res.data.message, 'success', 'Close');
                    shared.toastrSuccess(res.data.message);
                    this.getComplianceListData();
                    this.$bvModal.hide('business_category');
                    
                }
            }).catch((error) => {
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMsg = error.response.data.message;
            });             
        }
    },data(){
        return{
            items: [],
            fields: [
                { key: 'categoryName', label: 'Business Types',sortable: true,thClass:'th_sortfix'},
                { key: 'types', label: 'Group',thClass:'w-130-px'},
                { key: 'action', label: 'Action',thClass:'w-130-px'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            currentPageNo: 1,
            breadCrumbIteams:[
                {
                    title:'Category List',
                    links:''
                },
            ],

            businessCategory:{
                category_name:'',
                type:''
            },

            typeOptions:[],
            businessCategoryId: '',
            fieldName : '',
            fieldErrorMsg : '',
            apiErrorMsg : '',
            apiErrorCode: '',
            modalTitle: ''
        }
    },
    mounted() {
        if(sessionStorage.getItem("jwtToken")){
            // this.getComplianceListData();
            this.getBusinessCategoryTypes();
            // this.totalRows = this.items.length;
        } else {
           // this.$swal('Error!', 'Please login to continue', 'error', 'Close');
            shared.toastrError('Please login to continue');
            this.$router.push({path:'/standapp-login'});
        }
    }
}
</script>
